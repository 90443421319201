import React from "react"

import LayoutBackgroundBurrito from "../components/layout-background-burrito"


const VisitPage = () => {
  return (
    <LayoutBackgroundBurrito>
      <h1>Visit Page</h1>
      <h2>Here will be all the information to visit</h2>
    </LayoutBackgroundBurrito>
  )
}

export default VisitPage